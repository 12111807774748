<template>
  <div class="container">

    <el-row :gutter="20">
      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.company_date)">
          <div slot="header">
            <span>Компании</span>
            <el-switch class="float-right" v-model="settings.companies" @change="save"></el-switch>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.company_date)">
              {{ events.company_date }}
            </el-tag>
            <el-tag :type="getType(events.company_date)">
              {{ events.companies }}
            </el-tag>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.contact_date)">
          <div slot="header">
            <span>Контакты</span>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.contact_date)">
              {{ events.contact_date }}
            </el-tag>
            <el-tag :type="getType(events.contact_date)">
              {{ events.contacts }}
            </el-tag>
          </el-row>
        </el-card>
      </el-col>

      <el-col :lg="4" :md="6" :sm="12" :xl="4" :xs="24">
        <el-card class="box-card" :title="getTitle(events.deal_date)">
          <div slot="header">
            <span>Сделки</span>
          </div>
          <el-row type="flex" justify="space-between" align="middle">
            <el-tag :type="getType(events.deal_date)">
              {{ events.deal_date }}
            </el-tag>
            <el-tag :type="getType(events.deal_date)">
              {{ events.deals }}
            </el-tag>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import {saveOwnerSettings} from '../services/api';

export default {
  name: 'Main',
  mixins: [
  ],
  components: {
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'getLoading',
      settings: 'getOwnerSettings',
      events: 'getEvents',
    }),
  },
  mounted() {

  },
  methods: {
    async save() {
      this.saving = true;
      this.$store.commit('setOwnerSettings', this.settings);
      await saveOwnerSettings({settings: this.settings});
      const authType = 88;
      const eventHandler = process.env.VUE_APP_ENV_BASE_URL + '/api/owner/eventHandler';
      if (this.settings.companies === true) {
        BX24.callBind('onCrmCompanyAdd', eventHandler, authType);
        BX24.callBind('onCrmCompanyUpdate', eventHandler, authType);
      }
      if (this.settings.companies === false) {
        BX24.callUnbind('onCrmCompanyAdd', eventHandler);
        BX24.callUnbind('onCrmCompanyUpdate', eventHandler);
      }
      this.saving = false;
      this.$message.success('Настройки успешно сохранены');
    },
    getType(date) {
      if (date === '-') {
        return 'info';
      }
      const days = moment().diff(moment(date, 'DD.MM.YYYY'), 'days');
      if (days > 30) {
        return 'danger';
      }
      if (days > 7 && days <= 30) {
        return 'warning';
      }
      return 'success';
    },
    getTitle(date) {
      if (date === '-') {
        return 'Неактивна';
      }
      const days = moment().diff(moment(date, 'DD.MM.YYYY'), 'days');
      if (days > 30) {
        return 'Неактивна';
      }
      if (days > 7 && days <= 30) {
        return 'В ожидании';
      }
      return 'Активна';
    },
  }
};
</script>

<style scoped>
.container {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-bottom: 10px;
  width: 99%;
}
.float-right {
  float: right;
  padding: 3px 0;
}
</style>